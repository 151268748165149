.legal-texts {
  // padding: 32px 24px;
  margin-bottom: 20px;
  line-height: 20px;
  font-size: 14px;

  .legal-text-title {
    text-align: center;
    margin-top: 24px;
    margin-bottom: 8px;
    font-weight: 700;
  }

  h2 {
    font-size: 14px;
  }

  p {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  ul {
    list-style: disc;
    margin: 8px 0;
    padding-left: 64px;

    li {
      margin-bottom: 8px;
    }
  }

  .legal-text-table {
    display: flex;
    justify-content: center;
    margin: 24px 0;
    width: 100%;
    overflow: hidden;

    table {
      width: 90%;

      tbody {
        vertical-align: baseline;

        tr {
          border: 1px solid black;

          th {
            border: 1px solid black;
            width: 160px;
            font-weight: 700;
            text-align: left;
            vertical-align: middle;
            padding-bottom: 4px;
            padding-top: 4px;
            padding-left: 4px;
            // margin: 0;
          }

          td {
            padding: 4px;
          }
        }
      }
    }
  }

  .legal-text-contact {
    display: inline-block;

    p {
      margin-top: 8px;
      margin-bottom: 8px;
    }

    address {
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .cookie-legal-table {
    tr {
      td {
        vertical-align: middle;
        border: 1px solid black;
      }
    }
  }
}

@media screen and (max-width:670px) {
  .legal-text-table.scroll {
    display: block;
    overflow: auto;
  }
}