@use 'mixins' as *;
@use 'short-class' as *;
@use 'slider' as *;
@use 'kkarti-hero' as *;
@use 'kkarti-kamp-detay' as *;
@use 'kkarti-kampanyalar' as *;
@use 'header-desktop' as *;
@use 'header-mobil' as *;
@use 'accordion' as *;
@use 'how-it-works' as *;
@use 'credit-card-detail-table' as *;
@use 'legel-text' as *;
@use 'table-list-formatting' as *;
@use 'richtext-reset' as *;
@use 'pos-list-item' as *;
@use 'badge' as *;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  .modal-open {
    overflow: hidden;
  }
}